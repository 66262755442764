const emailOrUserNameRegExp = new RegExp('^[a-zA-Z0-9-_+=!#$.@\\s+]{2,100}$');
const passwordRegExp = new RegExp(
  '^(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ])(?=.*\\d)[|{_`\\-=+}?№!@#\\$%\\^&\\*\\(\\)\\[\\]:;\'",\\.A-Za-zа-яА-ЯЁё\\d]{8,30}$',
);

const noDigitRegExp = /[\D]/g;
const symbolInPhoneRegExp = /[\s()-]/g;
const phoneRegExp = '^[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{2}[0-9]{4}';
const phoneRegExpWithSpaces = '^\\([2-9]{1}[0-9]{2}\\) [2-9]{1}[0-9]{2} [0-9]{4}';
const emailRegExp =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mainBlockTextRegExp = new RegExp('^[^<>]{2,500}$');
const companyNameRegExp = '^[A-Za-z0-9 _]{2,30}$';

export {
  emailOrUserNameRegExp,
  passwordRegExp,
  noDigitRegExp,
  phoneRegExp,
  emailRegExp,
  phoneRegExpWithSpaces,
  mainBlockTextRegExp,
  companyNameRegExp,
  symbolInPhoneRegExp,
};
