import type { InputProps, StyleProps } from '@chakra-ui/react';
import { FormControl, Input } from '@chakra-ui/react';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import type { Control, FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { REQUIRED_FIELD_MSG } from '@/shared/lib';
import FormFieldMeta from './FormFieldMeta';
import LabelForm from './LabelForm';

export type TInputFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = InputProps & {
  control?: Control<TFieldValues>;
  name: TName;
  validationParameters?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
  label?: string;
  helperText?: string;
  containerStyle?: StyleProps;
};

const InputField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  placeholder,
  control,
  isRequired,
  validationParameters,
  label,
  helperText,
  containerStyle,
  onBlur,
  onChange,
  ...style
}: TInputFieldProps<TFieldValues, TName>) => {
  const {
    fieldState: { invalid, error },
    field: { onChange: onFieldChange, onBlur: onFieldBlur, ...restFiledProps },
  } = useController({
    name,
    control,
    rules: isRequired
      ? {
          required: REQUIRED_FIELD_MSG,
          ...validationParameters,
        }
      : validationParameters,
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onFieldChange(event);
    onChange?.(event);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    onFieldBlur();
    onBlur?.(event);
  };

  return (
    <FormControl
      isInvalid={invalid}
      isRequired={isRequired}
      {...containerStyle}
    >
      {label && <LabelForm label={label} />}
      <Input
        id={name}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        {...restFiledProps}
        {...style}
      />
      <FormFieldMeta
        errors={{ [name]: error }}
        helperText={helperText}
        name={name}
      />
    </FormControl>
  );
};

export default InputField;
