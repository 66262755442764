import { VStack } from '@chakra-ui/react';
import type { StackProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import type { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

interface FormProps<TFormValues extends FieldValues> {
  children: ReactNode;
  onSubmit: SubmitHandler<TFormValues>;
  childrenContainerProps?: StackProps;
  methods: UseFormReturn<TFormValues>;
  formId: string;
}

const Form = <TFormValues extends FieldValues>({
  children,
  onSubmit,
  childrenContainerProps = {},
  formId,
  methods,
}: FormProps<TFormValues>) => {
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <VStack
        as='form'
        id={formId}
        noValidate
        width='full'
        onSubmit={handleSubmit(onSubmit)}
        {...childrenContainerProps}
      >
        {children}
      </VStack>
    </FormProvider>
  );
};

export default Form;
