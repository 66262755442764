import { Box, FormHelperText } from '@chakra-ui/react';
import type { ContainerProps } from '@chakra-ui/react';
import { FC } from 'react';
import type { FieldErrors } from 'react-hook-form';
import ErrorMessageContainer from '../../ErrorMessageContainer';

interface ErrorMsgProps {
  dataTestId?: string;
  errors: FieldErrors;
  name: string;
  helperText?: string;
  errorTextStyles?: ContainerProps;
  helperTextStyles?: ContainerProps;
}

const FormFieldMeta: FC<ErrorMsgProps> = ({
  errors = {},
  name,
  helperText = '',
  helperTextStyles = {},
  errorTextStyles = {},
  dataTestId,
}) => (
  <Box minH={6}>
    <ErrorMessageContainer
      errorDataTestId={`${dataTestId}-error`}
      errors={errors}
      mt={0}
      name={name}
      position='relative'
      {...errorTextStyles}
    />
    {!errors?.[name] && (
      <FormHelperText
        mt={0}
        {...helperTextStyles}
      >
        {helperText || ''}
      </FormHelperText>
    )}
  </Box>
);

export default FormFieldMeta;
