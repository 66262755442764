import { Box } from '@chakra-ui/react';
import type { ContainerProps } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { FC } from 'react';

interface ErrorMsgProps {
  errorDataTestId?: string;
  errors: object;
  name: string;
  styles?: ContainerProps;
}

const ErrorMessageContainer: FC<ErrorMsgProps> = ({
  errorDataTestId,
  errors,
  name = '',
  ...styles
}) => (
  <ErrorMessage
    errors={errors || {}}
    name={name}
    render={({ message }) => (
      <Box pos='relative'>
        <Box
          data-testid={errorDataTestId}
          fontSize='xs'
          mt='3xs'
          position='absolute'
          {...styles}
        >
          {message}
        </Box>
      </Box>
    )}
  />
);

export default ErrorMessageContainer;
