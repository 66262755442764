import { Center, Heading, Text, VStack } from '@chakra-ui/react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useId, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { authModel } from '@/entities/Auth';
import { configModel } from '@/entities/Config';
import { profileClientModel } from '@/entities/Profile';
import { Form, FormControls, InputField, PasswordField } from '@/sharedUI';
import { INVALID_CHARACTERS_MSG, emailOrUserNameRegExp } from '@/sharedLib';

const USER_NAME_PLACEHOLDER = 'Email or user name';

const defaultValues = {
  userName: '',
  password: '',
};

type TLoginFormValues = typeof defaultValues;

const LoginPage: NextPage = () => {
  const router = useRouter();
  const [isSuccessLogin, setIsSuccessLogin] = useState(false);

  const onSuccessLogin = () => {
    setIsSuccessLogin(true);
  };

  const { me } = authModel.useGetMe();

  const { updateConfig } = configModel.useStoreMethods();
  const { editConfig, isLoading: isEditConfigLoading } = profileClientModel.useEditConfig({
    pause: !me,
  });

  const methods = useForm({
    mode: 'onBlur',
    defaultValues,
  });
  const formId = useId();

  const onLoginError = (error: { message: string }) => {
    methods.setError('root', { message: error.message });
  };

  const { loginAction, isLoading: isLoginLoading } = authModel.useLogin({
    onSuccess: onSuccessLogin,
    onError: onLoginError,
  });

  const {
    formState: { isValid, isSubmitted },
  } = methods;

  const onSubmit: SubmitHandler<TLoginFormValues> = async ({ userName, password }) =>
    loginAction({
      input: {
        userName,
        password,
      },
    });

  useEffect(() => {
    if (me && isSuccessLogin && !isEditConfigLoading) {
      editConfig && updateConfig(editConfig);

      const callbackUrl = router.query.callbackUrl;

      router.replace(typeof callbackUrl === 'string' ? callbackUrl : '/');
    }
  }, [me, editConfig, updateConfig, router, isEditConfigLoading, isSuccessLogin]);

  const formError = methods.formState.errors.root?.message;

  return (
    <Center
      flexDirection='column'
      h='100vh'
    >
      <VStack
        bg='bg.pure'
        border='1px solid'
        borderColor={formError ? 'error.pure' : 'border.medium'}
        borderRadius='lg'
        gap='xl'
        p={{ base: 'sm', md: 'xl' }}
        w={{ base: '95%', md: '50%' }}
      >
        <Heading
          color='primary.medium'
          fontSize={{ base: 'lg', md: '2xl' }}
          textAlign='center'
        >
          Login to manage your landing
        </Heading>

        <Form
          formId={formId}
          methods={methods}
          onSubmit={onSubmit}
        >
          <InputField
            isRequired
            name='userName'
            placeholder={USER_NAME_PLACEHOLDER}
            validationParameters={{
              pattern: { value: emailOrUserNameRegExp, message: INVALID_CHARACTERS_MSG },
            }}
            onChange={() => methods.clearErrors('root')}
          />
          <PasswordField onChange={() => methods.clearErrors('root')} />
          <FormControls
            formId={formId}
            isLoading={isLoginLoading || isEditConfigLoading || isSuccessLogin}
            isPositiveDisabled={isSubmitted && !isValid}
            positiveText='Log in'
          />
        </Form>
      </VStack>
      {formError && (
        <Text
          color='error.pure'
          mt={2}
        >
          {formError}
        </Text>
      )}
    </Center>
  );
};

export default LoginPage;
