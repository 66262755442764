import { DeleteIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';
import { ElementType, FC } from 'react';
import { configModel } from '@/entities/Config';

type ButtonWithConfirmProps = {
  PreviewButton?: ElementType<ButtonProps>;
  header?: string;
  message?: string;
  messageHighlight?: string[] | string;
  secondText?: string;
  positiveButtonText?: string;
  negativeButtonText?: string;
  showConfirm?: boolean;
} & ButtonProps;

const DefaultDeleteButton: FC<ButtonProps> = (props) => (
  <IconButton
    aria-label='Confirm'
    color='typography.secondary'
    data-testid='delete-data'
    icon={<DeleteIcon />}
    size='sm'
    {...props}
  />
);

const ButtonWithConfirm: FC<ButtonWithConfirmProps> = ({
  onClick,
  PreviewButton = DefaultDeleteButton,
  showConfirm = true,
  header,
  message = 'Are you sure?',
  messageHighlight = '',
  secondText,
  positiveButtonText = 'Ok',
  negativeButtonText = 'Cancel',
  ...buttonProps
}) => {
  const { openConfirmPopup } = configModel.useStoreMethods();

  const handleClick: ButtonProps['onClick'] = (e) => {
    if (showConfirm) {
      openConfirmPopup({
        header,
        message,
        messageHighlight,
        secondText,
        positiveButtonText,
        negativeButtonText,
        onConfirm: onClick,
      });
    } else {
      onClick?.(e);
    }
  };

  return (
    <PreviewButton
      onClick={handleClick}
      {...buttonProps}
    />
  );
};

export default ButtonWithConfirm;
